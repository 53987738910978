import React, { useRef, useEffect } from 'react';

declare let window: Window & { Trustpilot: any };

const Trustpilot: React.FC = () => {
    const ref = useRef(null);

    useEffect(() => {
        const scriptElement = document.getElementById('tp-widget');

        if (scriptElement !== null) {
            return;
        }

        const script = document.createElement('script');
        script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
        script.id = 'tp-widget';
        script.async = true;

        document.head.appendChild(script);

        return () => {
            if (scriptElement) {
                document.head.removeChild(script);
            }
        };
    }, []);

    if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true);
    }

    return (
        <>
            <div
                ref={ref}
                className="trustpilot-widget"
                data-locale="de-DE"
                data-template-id="53aa8912dec7e10d38f59f36"
                data-businessunit-id="5df8af54b30f2300017c5da7"
                data-style-height="140px"
                data-style-width="100%"
                data-stars="5"
                data-review-languages="de"
            ></div>
        </>
    );
};

export default Trustpilot;

import React from 'react';
import { encapsulateData } from 'components/signup';
import { useParams } from 'react-router-dom';

export const PartnerLogo = () => {
    const { programName } = useParams<{ programName: string }>();
    const { partner } = encapsulateData(programName);

    if (!programName || !partner?.LOGO) {
        return <></>;
    }

    return <img src={partner.LOGO} alt={partner.KEY} className="partner-logo" />;
};

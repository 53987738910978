import React from 'react';
import Typography from '@gymondo/frontend-core/typography';

type BarmerCustomSignupInfoProps = {
    programTitle?: string;
};

export const BarmerCustomSignupInfo = ({ programTitle }: BarmerCustomSignupInfoProps) => {
    return (
        <>
            <Typography as="h2" variant="h4">
                Starte dein Online-Training
            </Typography>

            <div className="p">
                Nur noch ein Klick und du kannst mit deinem Kurs <strong>{programTitle}</strong> loslegen. Die
                Registrierung ist für dich kostenfrei.
            </div>

            <div className="p">
                Unser Geschenk an deine Gesundheit: Ab dem Zeitpunkt deiner Kursanmeldung kannst du mit der BARMER{' '}
                <strong>Gymondo 6 Monate lang kostenlos</strong> nutzen. Den Zugang erhältst du nach deiner Anmeldung
                direkt per E-Mail.
            </div>

            <div></div>
        </>
    );
};

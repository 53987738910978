import { useEffect, useState } from 'react';
import { getEnvVar } from 'utils/env';
import { ExternalIdentityProviderProps } from '../use-external-identity-provider';

export const barmerIdentityProvider = (sessionKey: string, userId: string): ExternalIdentityProviderProps => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAuthenticating, setIsAuthenticating] = useState(false);
    const [hasAuthenticationError, setHasAuthenticationError] = useState(false);

    useEffect(() => {
        const fetchUserStatus = async () => {
            const BARMER_IDP_URL = getEnvVar('BARMER_IDP_URL');

            if (!BARMER_IDP_URL) {
                console.error('Invalid configuration for Barmer IDP');

                setHasAuthenticationError(true);

                return;
            }

            const url = `${BARMER_IDP_URL}?sessionKey=${sessionKey}&userId=${userId}`;

            setIsAuthenticating(true);

            try {
                const response = await fetch(url);

                const data = await response.json();

                if (data.valid && data.userType === 'VERSICHERTER') {
                    setIsAuthenticated(true);
                }
            } catch (error) {
                console.error(error);
                setHasAuthenticationError(true);
            } finally {
                setIsAuthenticating(false);
            }
        };

        fetchUserStatus();
    }, [sessionKey, userId]);

    return {
        isAuthenticated,
        isAuthenticating,
        hasAuthenticationError,
        externalUserId: userId,
    };
};

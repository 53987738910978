import { Partner, PARTNER_KEYS } from 'state/config';
import { barmerIdentityProvider } from './providers/barmer';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

export type ExternalIdentityProviderProps = {
    isAuthenticated: boolean;
    isAuthenticating: boolean;
    hasAuthenticationError: boolean;
    externalUserId: string | null;
};

export const useExternalIdentityProvider = (partner: Partner): ExternalIdentityProviderProps => {
    const { search } = useLocation();
    const params = qs.parse(search || '');

    if (
        partner.USES_EXTERNAL_IDENTITY_PROVIDER &&
        partner.KEY === PARTNER_KEYS.BARMER &&
        params.sessionKey &&
        params.userId
    ) {
        return barmerIdentityProvider(params.sessionKey as string, params.userId as string);
    }

    return {
        isAuthenticated: false,
        isAuthenticating: false,
        hasAuthenticationError: false,
        externalUserId: null,
    };
};

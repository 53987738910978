import React, { createRef, useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import moment from 'moment';
import { Button, LoadingSpinner, Modal } from '@gymondo/frontend-core/components';
import Typography from '@gymondo/frontend-core/typography';

import {
    dispatch,
    DispatchActions,
    dispatchCheckVoucher,
    dispatchSignIn,
    dispatchSignUp,
    DispatchSignUpArgs,
} from 'state/actions';
import {
    clearStoredInsuranceData,
    getStoredContraindications,
    getStoredInsuranceData,
    getStoredPartnerKey,
    getStoredProductId,
    getStoredProgramId,
    getStoredShowVoucher,
    getStoredVoucherCode,
    storeContraindications,
    storeInsuranceData,
    storePartnerKey,
    storeProductId,
    storeProgramId,
    storeRedeemedVoucher,
} from 'state/session';
import { AppStateContext } from 'state/context';
import config, {
    GYM_12M_REWARD_PRODUCTS,
    GANZKOERPER_FREEBIE_12M_PRODUCT_ID,
    PARTNER_KEYS,
    PATHS,
    PROGRAM_NAME,
} from '../../state/config';
import { ApiError } from 'utils/error';
import text from 'lang';
import { redirect } from 'utils/system';

import { ContraindicationsNote, ReplaceProgramForm, SignInModal, SignUpForm } from '..';
import { getPartner, getPartnerByProductId, getProgram, getProgramByName } from 'state/helpers/program';
import { Voucher } from 'state/api.types';
import CannotPurchaseProduct from 'components/static/CannotPurchaseProduct';
import { Tracker } from '@gymondo/frontend-core/utils';
import { useLocation } from 'react-use';
import qs from 'query-string';
import { useMediaQuery } from '@gymondo/frontend-core/hooks';
import style from './singup.module.scss';
import classNames from 'classnames';
import { useExternalIdentityProvider } from './external-idp/use-external-identity-provider';
import { getEnvVar } from 'utils/env';

// reward program is constrained with Ganzkörper atm
// logic is isolated to help cleanup later
const useRewardProgram = (programName = '') => {
    const nullableReturn = { program_id: null, product_id: null, program: null, partner: null };

    if (programName === PROGRAM_NAME.GANZKOERPER) {
        const program = getProgramByName(programName);
        const partner = getPartnerByProductId(GANZKOERPER_FREEBIE_12M_PRODUCT_ID);
        if (!program || !partner) {
            return nullableReturn;
        }
        const product_id = GANZKOERPER_FREEBIE_12M_PRODUCT_ID;
        storeProductId(GANZKOERPER_FREEBIE_12M_PRODUCT_ID);
        storeProgramId(program.ID);
        storePartnerKey(partner.KEY);

        return { program_id: program.ID, product_id, program, partner };
    }

    return nullableReturn;
};

export function encapsulateData(programName = '') {
    // load stored from session
    let program_id = getStoredProgramId();
    let product_id = getStoredProductId();
    let program = getProgram(program_id);
    const partner_key = getStoredPartnerKey();
    let partner = getPartnerByProductId(product_id);

    // if program name set, search and save session storage data for this program
    if (programName) {
        program = getProgramByName(programName);
        if (program) {
            program_id = program.ID;
            storeProgramId(program_id);
            partner = getPartner(program_id, partner_key);
            if (partner) {
                product_id = partner.PRODUCT_ID;
                storeProductId(product_id);
            }
        }
    }
    return { program_id, product_id, program, partner };
}

const SignUp: React.FC = () => {
    const context = useContext(AppStateContext);
    const history = useHistory();
    const { programName } = useParams<{ programName: string }>();
    const { search } = useLocation();
    const isXs = useMediaQuery('(max-width: 767px)');

    // directly land reward program - temporary workaround
    const params = qs.parse(search || '');
    const isRewardPrg = Object.keys(params).includes('reward');

    const { program_id, program, partner, product_id } = isRewardPrg
        ? useRewardProgram(programName)
        : encapsulateData(programName);

    if (!program || !partner) {
        redirect(PATHS.HOMEPAGE);
        return <LoadingSpinner type="page" />;
    }

    const { isAuthenticated, isAuthenticating, hasAuthenticationError, externalUserId } =
        useExternalIdentityProvider(partner);

    if (isAuthenticated) {
        partner.DISCLAIMER_CONFIRMATION_TEXT = undefined;
        partner.HAS_VOUCHER = false;
    }

    const [contraindicationsModal, setContraindicationsModal] = useState(false);
    const [replaceProgramModal, setReplaceProgramModal] = useState(false);
    const [cannotPurchaseRewardModal, setCannotPurchaseRewardModal] = useState(false);
    const [showResetPasswordLink, setShowResetPasswordLink] = useState(false);
    const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);

    const storedContraindications = getStoredContraindications();

    // if show_voucher set -> make has_voucher=true for partner object (simulating value, so as not to pass in form one more variable for show_voucher)
    if (getStoredShowVoucher()) {
        partner.HAS_VOUCHER = true;
    }
    const initialVoucher = getStoredVoucherCode();
    const [checkedVoucher, setCheckedVoucher] = useState<Voucher | null>(null);
    const isFreebie = partner.IS_FREEBIE;

    const contraModalRef = createRef<HTMLDivElement>();
    const replaceModalRef = createRef<HTMLDivElement>();
    const cannotPurchaseModalRef = createRef<HTMLDivElement>();

    useEffect(() => {
        // if user signed in (after handleSignUp)
        if (context.appState.isAuthenticated) {
            const user = context.appState.user;
            if (user.isLoaded) {
                // if existing user tries to purchase second program
                if (user.paymentCompleted) {
                    if (user.programId === program_id) {
                        redirect(PATHS.HOMEPAGE);
                        return;
                    }
                    if (
                        product_id &&
                        GYM_12M_REWARD_PRODUCTS.includes(product_id) &&
                        user.account.roles?.includes('ROLE_USER')
                    ) {
                        setCannotPurchaseRewardModal(true);
                        return;
                    }
                    setReplaceProgramModal(true);
                } else {
                    // check if user can purchase
                    if (context.appState.user.canPurchaseOn > Date.now()) {
                        redirect(PATHS.PURCHASE_NOT_ALLOWED);
                        return;
                    }

                    if (
                        product_id &&
                        GYM_12M_REWARD_PRODUCTS.includes(product_id) &&
                        user.account.roles?.includes('ROLE_USER')
                    ) {
                        setCannotPurchaseRewardModal(true);
                        return;
                    }
                    redirect(PATHS.PAYMENT);
                }
            }
        }
    }, [context]);

    useEffect(() => {
        /* hook: do not close modal on clicking outside (wrapper) */
        let dialogWrapper;
        if (contraindicationsModal) {
            dialogWrapper = contraModalRef.current?.closest('div[class*="dialogWrapper"]');
        }
        if (replaceProgramModal) {
            dialogWrapper = replaceModalRef.current?.closest('div[class*="dialogWrapper"]');
        }
        if (dialogWrapper) {
            dialogWrapper.addEventListener('click', function onClick(this: unknown, event) {
                if (event.target === this) {
                    return event.stopPropagation();
                }
            });
        }
    }, [contraindicationsModal, replaceProgramModal]);

    useEffect(() => {
        if (!storedContraindications && !context.appState.isAuthenticated) {
            setContraindicationsModal(true);
        }
    }, [storedContraindications, context.appState.isAuthenticated]);

    useEffect(() => {
        Tracker.push({
            event: Tracker.EVENTS.TRACKING,
            eventCategory: 'prevention',
            eventAction: 'add_to_cart',
            eventLabel: product_id,
        });
    }, []);

    // callback handler for registration (signup, signin, redeem voucher, go next step)
    const handleSignUp = useCallback(
        async ({ email, password, insurance_number }: DispatchSignUpArgs) => {
            if (isAuthenticated && !insurance_number) {
                insurance_number = `UID_${externalUserId}`;
            }

            try {
                clearStoredInsuranceData(); // prevents insurance data to be kept in session when partner changes

                // set redeemed voucher to be used in payment
                if (checkedVoucher) {
                    storeRedeemedVoucher(checkedVoucher);
                }

                await dispatchSignUp({ email, password, insurance_number, isFreebie }, context);

                // sign in
                await dispatchSignIn({ email, password }, context);
            } catch (error: unknown) {
                const _err = error as Record<string, unknown>;
                const _errData = _err.data as Record<string, unknown>;
                // user exists, try to sign in
                if (_errData.code === config.ERROR_CODES.DUPLICATE_USER_EXCEPTION) {
                    try {
                        await dispatchSignIn({ email, password }, context);
                        // if user already exists, we need to patch to update kkv
                        if (insurance_number && !getStoredInsuranceData()) {
                            storeInsuranceData({
                                insuranceNumber: insurance_number,
                            });
                        }
                    } catch (_error: unknown) {
                        setShowResetPasswordLink(true);
                        throw new ApiError(_err);
                    }
                } else {
                    throw new ApiError(_err);
                }
            }
        },
        [context, checkedVoucher],
    );

    // callback handler for add or remove voucher code
    const handleCheckVoucher = useCallback(async (voucher_code: string) => {
        try {
            setCheckedVoucher(null);
            if (voucher_code && product_id) {
                const voucher = await dispatchCheckVoucher(voucher_code, product_id);
                setCheckedVoucher(voucher);
            }
        } catch (error: unknown) {
            const _err = error as Record<string, unknown>;
            throw new ApiError(_err);
        }
    }, []);

    // callback handler for reset password link
    const handleClickResetPassword = useCallback(() => {
        // show reset password form
        setShowResetPasswordModal(!showResetPasswordModal);
    }, [showResetPasswordModal]);

    // callback handler for 'user has Contraindications'
    const handleRejectContraindications = useCallback(() => {
        Tracker.push({
            event: Tracker.EVENTS.TRACKING,
            eventCategory: 'prevention',
            eventAction: 'accept_contraindications',
            eventLabel: 'false',
        });
        setContraindicationsModal(false);
        history.push(PATHS.HOMEPAGE);
    }, []);

    // callback handler for 'user has no Contraindications'
    const handleAcceptContraindications = useCallback(() => {
        Tracker.push({
            event: Tracker.EVENTS.TRACKING,
            eventCategory: 'prevention',
            eventAction: 'accept_contraindications',
            eventLabel: 'true',
        });
        setContraindicationsModal(false);
        storeContraindications();
    }, []);

    // callback handler for 'do not replace program'
    const handleRejectReplaceProgram = useCallback(() => {
        setReplaceProgramModal(false);
        redirect(PATHS.HOMEPAGE);
    }, []);

    // callback handler for 'replace program'
    const handleAcceptReplaceProgram = useCallback(async () => {
        setReplaceProgramModal(false);

        // end current plan
        await dispatch(DispatchActions.endProgramPlan, { plan_id: context.appState.user.planId });
        redirect(PATHS.PAYMENT);
    }, [context]);

    const freeSignup = isFreebie || !!checkedVoucher;

    if (isAuthenticating) {
        return <LoadingSpinner type="page" />;
    }

    if (hasAuthenticationError) {
        return (
            <Modal
                ref={cannotPurchaseModalRef}
                dialogClassName="signup-modal wide"
                header={text('cannot_purchase_product_header')}
                hideFooter
                onClose={() => {
                    setCannotPurchaseRewardModal(false);
                    redirect(PATHS.HOMEPAGE);
                }}
            >
                <div
                    style={{
                        width: 'auto',
                    }}
                >
                    <div className="cannot_purchase">
                        <p>
                            Wir konnten dich leider nicht authentifizieren. Bitte versuche es erneut oder wende dich an
                            den Kundenservice.
                        </p>
                        <div className="row end-xs">
                            <a href={getEnvVar('FRONTEND_POSTLOGIN_URL')} className="gym-btn no-margin">
                                <Button>{text('cannot_purchase_product_to_gymondo')}</Button>
                            </a>
                            <a href={PATHS.HOMEPAGE} className="no-margin">
                                <Button className="btn--border">{text('cannot_purchase_product_to_homepage')}</Button>
                            </a>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }

    return (
        <>
            <div className="container wrapper spacing-pt-pb-0 register first-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-7 col-xs-offset-0 col-sm-offset-2 col-md-offset-2 spacing-tb-xl no-spacing-bottom">
                        {!partner.CUSTOM_REGISTRATION_COMPONENT ? (
                            <>
                                <Typography as="h2" variant="h4">
                                    Starte dein Präventionsprogramm
                                </Typography>
                                <div className="p">
                                    Nur noch ein Klick und du kannst mit deinem Kurs <strong>{program?.TITLE}</strong>{' '}
                                    loslegen.
                                    {!freeSignup && (
                                        <span>
                                            &nbsp;Du bezahlst einmalig <strong>{program?.PRICE}</strong> €.
                                        </span>
                                    )}
                                    {freeSignup && <div>Die Registrierung ist für dich kostenfrei.</div>}
                                </div>
                            </>
                        ) : (
                            partner.CUSTOM_REGISTRATION_COMPONENT
                        )}
                        <SignUpForm
                            onSubmit={handleSignUp}
                            onCheckVoucher={handleCheckVoucher}
                            onClickResetPassword={handleClickResetPassword}
                            showResetPassword={showResetPasswordLink}
                            initialVoucher={initialVoucher}
                            checkedVoucher={checkedVoucher}
                            partner={partner}
                        />
                    </div>
                </div>
            </div>
            {contraindicationsModal && (
                <Modal
                    ref={contraModalRef}
                    persistent
                    dialogClassName={classNames('simple-modal', style.contraindications)}
                    onClose={handleRejectContraindications}
                    fullScreen={isXs}
                    footer={
                        <>
                            <div className="col-xs-12 col-sm-6 last-xs first-sm">
                                <Button variant="primary" fluid onClick={handleRejectContraindications}>
                                    {text('contraindications_no_label')}
                                </Button>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <Button fluid onClick={handleAcceptContraindications}>
                                    {text('contraindications_yes_label')}
                                </Button>
                            </div>
                        </>
                    }
                >
                    <div
                        style={{
                            width: 'auto',
                        }}
                    >
                        <ContraindicationsNote />
                    </div>
                </Modal>
            )}
            {showResetPasswordModal && <SignInModal onClose={handleClickResetPassword} resetPassword />}
            {replaceProgramModal && (
                <Modal
                    ref={replaceModalRef}
                    dialogClassName="signup-modal gray-modal wide"
                    header={text('signup_replace_program_header')}
                    hideFooter
                    onClose={handleRejectReplaceProgram}
                >
                    <div
                        style={{
                            width: 'auto',
                        }}
                    >
                        <ReplaceProgramForm
                            programName={program?.TITLE as string}
                            handleAccept={handleAcceptReplaceProgram}
                            handleReject={handleRejectReplaceProgram}
                        />
                    </div>
                </Modal>
            )}
            {cannotPurchaseRewardModal && (
                <Modal
                    ref={cannotPurchaseModalRef}
                    dialogClassName="signup-modal wide"
                    header={text('cannot_purchase_product_header')}
                    hideFooter
                    onClose={() => {
                        setCannotPurchaseRewardModal(false);
                        redirect(PATHS.HOMEPAGE);
                    }}
                >
                    <div
                        style={{
                            width: 'auto',
                        }}
                    >
                        <CannotPurchaseProduct />
                    </div>
                </Modal>
            )}
        </>
    );
};

export default SignUp;

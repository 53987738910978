import React from 'react';

const Imprint: React.FC = () => {
    return (
        <>
            <div className="container_ wrapper legal first-container">
                <div className="row">
                    <div className="col-xs-12 col-sm-8 col-md-6 col-xs-offset-0 col-sm-offset-2 col-md-offset-3">
                        <h1>Impressum</h1>
                        <br />
                        <br />
                        Angaben gem. § 5 TMG
                        <br />
                        <br />
                        <b>Gymondo ist ein Dienst der Gymondo GmbH</b>
                        <br />
                        <b>Ritterstraße 12-14</b>
                        <br />
                        <b>10969 Berlin</b>
                        <br />
                        <b>E-Mail:</b>{' '}
                        <a href="mailto:service@gymondo.de" className="link">
                            service@gymondo.de
                        </a>
                        <br />
                        <b>Web:</b>{' '}
                        <a href="https://www.gymondo.com" className="link" target="_blank" rel="noreferrer">
                            https://www.gymondo.com
                        </a>
                        <br />
                        <br />
                        Geschäftsführer: Markan Karajica, Johann Griebl
                        <br />
                        <br />
                        Registergericht:Berlin (Charlottenburg), HRB 158406
                        <br />
                        <br />
                        Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz:
                        <br />
                        USt-ID Nr. DE288729497
                        <br />
                        <br />
                        Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
                        <br />
                        Marife Sanvictores
                        <br />
                        Ritterstraße 12-14
                        <br />
                        10969 Berlin
                        <br />
                        <br />
                        <b>Informationen zur Online-Streitbeilegung:</b>
                        <br />
                        <br />
                        Die EU-Kommission hat im ersten Quartal 2016 eine Internetplattform zur Online-Beilegung von
                        Streitigkeiten (sog. „OS-Plattform“) bereitgestellt. Die OS-Plattform soll als Anlaufstelle zur
                        außergerichtlichen Beilegung von Streitigkeiten betreffend vertragliche Verpflichtungen, die aus
                        Online-Kaufverträgen erwachsen, dienen.
                        <br />
                        Die OS-Plattform wird unter folgendem Link erreichbar sein:{' '}
                        <a href="http://ec.europa.eu/consumers/odr" className="link" target="_blank" rel="noreferrer">
                            http://ec.europa.eu/consumers/odr
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Imprint;
